<template>
<div>
    <div style="padding: 40px 60px;">
        <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
            <el-form-item label="新密码" prop="pass">
                <el-input type="password" v-model="form.pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
                <el-input type="password" v-model="form.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</div>
</template>

<script>
import api from "../../api"
export default {
    data() {
        let ret = {
            form: {
                pass: "",
                check_pass: "",
            },
            rules: {
                pass: [
                    {
                        validator: (rule, value, cb) => {
                            if (this.form.pass !== '' || this.form.checkPass !== '') {
                                this.$refs.form.validateField('checkPass');
                            }
                            cb();
                        },
                        trigger: 'blur',
                    }
                ],
                checkPass: [
                    {
                        validator: (rule, value, cb) => {
                            if (value === '') {
                                if (this.form.pass === '') cb();
                                else cb(new Error("请再次输入密码"));
                            } else {
                                if (this.form.pass != value) cb(new Error("两次输入的密码不一致"));
                                else cb();
                            }
                        },
                        trigger: 'blur'
                    }
                ]
            }
        };
        return ret;
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    api.updateMyPassword(this.form.pass).then(res => {
                        if (res.code == 0) {
                            this.$message.success("修改密码成功！");
                        } else {
                            this.$message.error(res.message);
                        }
                        this.loading = false;
                    }).catch(err => {
                        this.$message.error(err.toString());
                        console.error(err);
                        this.loading = false;
                    });
                } else {
                    this.$message.error("请确认你的输入是正确的！");
                    return false;
                }
            });
        },
    }
}
</script>